import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import '../../styles/globals.css';
import { MetaComponent } from '../../components';
import {
  SassyText,
  Title,
  Section,
  Text,
  Button,
  Divider,
  SectionWrapper,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import { LeadFormProvider } from '../../context/LeadFormContext';

import Bullet from '../../assets/images/bullet.svg';

const HelpSection = loadable(() => import("../../components/HelpSection"));
const PolicyHeader = loadable(() => import("../../components/PolicyHeader"));

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const DNO = () => {
  const {hindiBold, hindiRegular, hindiBlack} = useHindiLangFont()
  const {t} = useTranslation(['dno', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Directors and Officers (D&O) Insurance - Verak"
        description="Everyone makes mistakes. But your director or top executive's mistake may cost your business a lot. Get D&O Insurance now."
        image="dno"
        url="liability/directorsandofficers"
      />
      <Navbar active="products" />
      <PolicyHeader
        acronym="DNO"
        title={
          <Trans i18nKey="Title" className={hindiBlack}>
            <SassyText>Directors and Officers </SassyText>Insurance
          </Trans>
        }
        content={t('Desc1')}
        subcontent={
          <span className={hindiRegular}>
            <Trans i18nKey="Desc2">
              <strong className={hindiBold}>Directors and Officers (D&O) Liability Insurance</strong>
              <br />
              seeks to extend financial protection to businesses in managing their
              legal liability that arises as a result of lawsuits claiming gross
              negligence, wrongdoing or misconduct by a director or officer.
            </Trans>
          </span>
        }
      />
      <Section background="#FFF7F1">
        <SectionWrapper>
          <Title style={{ paddingBottom: '20px' }} className={hindiBlack}>
            {t('WhatIsCovered.Title')}
          </Title>
          <Left>
            <Text mfontSize="16px" className={hindiRegular}>
              {t('WhatIsCovered.Desc')}
            </Text>
            <Text mfontSize="16px" className={hindiRegular}>
              {t('WhatIsCovered.ClaimReasonsCovered')}
            </Text>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.Investigations')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.AccountingErr')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.HarrasmentAllegations')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.ShareholderClaims')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.MergerAcquisitions')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.ComplianceFailure')}
                  </Text>
                </li>
              </ul>
              <Text color="#999999" mfontSize="16px" className={hindiRegular}>
                {t('WhatIsCovered.CoverageVaries')}
              </Text>
            </Container>
          </Left>
        </SectionWrapper>
      </Section>
      <Section background="#ffffff">
        <SectionWrapper>
          <Title className={hindiBlack}>{t('WhyOptForDNO.Title')}</Title>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForDNO.EssentialCover.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForDNO.EssentialCover.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForDNO.AllInclusiveCover.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                     {t('WhyOptForDNO.AllInclusiveCover.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForDNO.ExtendedCover.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForDNO.ExtendedCover.Desc')}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text
              fontSize="16px"
              mfontSize="16px"
              style={{ paddingBottom: '50px' }}
              className={hindiRegular}
            >
              <Trans i18nKey="WhyOptForDNO.LearnAboutDNO">
                Learn about <strong>Directors and Officers Insurance</strong> in
                detail
                <a href="https://blog.verak.in/directors-and-officers-liability-insurance-amp/">
                  <SassyText> HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - D&O LP")} />
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["dno", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin: 20px 0px;

  .bullet {
    min-width: 12px;
    margin-left: -20px;
    margin-right: 35px;
  }

  p {
    display: flex;
    align-items: center;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 24px;
    list-style: none;
  }
`;

export default DNO;
